:root {
    --animation-speed: 2s;
    --primary-color: #000;
    --secondary-color: #000;

    --text-color: #fff;
    --font: sans-serif;
}

* {
    margin: 0;
    padding: 0;
}

.rain-container {
    position: relative;

    /*   background: url("https://rb.gy/ufafte") no-repeat center center fixed; */
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    object-fit: cover;

}

i {
    position: absolute;
    height: 120px;
    border-radius: 0 0 999px 999px;
    animation: animate 5s linear infinite;
}

@keyframes animate {
    0% {
        transform: translateY(-120px);
    }

    100% {
        transform: translateY(calc(100vh + 120px));
    }
}